<template>
  <v-container id="user-profile" fluid tag="section">
    <v-row justify="center">
      <v-col cols="12" md="8">
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2 font-weight-light">Perfil de compañia</div>

            <div class="subtitle-1 font-weight-light">Completa tu perfil</div>
          </template>

          <v-form v-model="valid">
            <v-container class="py-0" v-if="user">
              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field
                    :rules="[rules.required, rules.sqlSafe]"
                    v-model="user.operator"
                    label="Compañia (disabled)"
                    disabled
                  />
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    :rules="[rules.required, rules.sqlSafe]"
                    v-model="user.name"
                    class="purple-input"
                    label="Nombre de usuario"
                  />
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    :rules="[rules.required, rules.nameSafe]"
                    v-model="user.email"
                    label="Email"
                    class="purple-input"
                    disabled
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    :rules="[rules.required, rules.sqlSafe]"
                    v-model="user.first_name"
                    label="Nombre"
                    class="purple-input"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    :rules="[rules.required, rules.sqlSafe]"
                    v-model="user.last_name"
                    label="Apellidos"
                    class="purple-input"
                  />
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    :rules="[rules.required, rules.sqlSafe]"
                    v-model="user.address"
                    label="Direccion"
                    class="purple-input"
                  />
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    :rules="[rules.required, rules.sqlSafe]"
                    v-model="user.city"
                    label="Ciudad"
                    class="purple-input"
                  />
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    :rules="[rules.required, rules.sqlSafe]"
                    v-model="user.country"
                    label="Provincia"
                    class="purple-input"
                  />
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    :rules="[rules.required, rules.sqlSafe]"
                    v-model="user.postal_code"
                    class="purple-input"
                    label="Codigo Postal"
                    type="number"
                  />
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    :rules="[rules.sqlSafe]"
                    v-model="user.about_me"
                    class="purple-input"
                    label="Observaciones"
                    value="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                  />
                </v-col>

                <v-col cols="12" class="text-right">
                  <v-btn
                    :disabled="submitDisabled"
                    color="success"
                    class="mr-0"
                    @click="updateUser()"
                  >
                    Actualizar perfil
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>

      <!-- <v-col cols="12" md="4">
        <base-material-card class="v-card-profile"
          avatar="https://demos.creative-tim.com/vue-material-dashboard/img/marc.aba54d65.jpg">
          <v-card-text class="text-center">
            <h6 class="display-1 mb-1 grey--text">
              CEO / CO-FOUNDER
            </h6>

            <h4 class="display-2 font-weight-light mb-3 black--text">
              Alec Thompson
            </h4>

            <p class="font-weight-light grey--text">
              Don't be scared of the truth because we need to restart the human foundation in truth And I love you like
              Kanye loves Kanye I love Rick Owens’ bed design but the back is...
            </p>

            <v-btn color="success" rounded class="mr-0">
              Follow
            </v-btn>
          </v-card-text>
        </base-material-card>
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import router from "../../../router.js";

export default {
  data() {
    return {
      user: null,
      valid: false,
      rules: {
        required: (value) => !!value || "Campo necesario",
        min: (v) => v.length >= 7 || "Min 7 caracteres",
        emailMatch: () => `The email and password you entered don't match`,
        sqlSafe: (v) =>
          /^[a-zA-Z0-9_@#'ñÑ,.()\[\]\s-]+$/.test(v) ||
          "No se permiten caracteres especiales en este campo",
        nameSafe: (v) =>
          /^[a-zA-Z0-9.-]+@[a-zA-Z0-9.-]+\.[a-z]+$/i.test(v) ||
          "Solo se permiten emails",
      },
    };
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  computed: {
    submitDisabled() {
      return !this.valid; // devuelve `true` si el formulario no es válido
    },
  },
  methods: {
    async updateUser() {
      const token = localStorage.getItem("token");
      await axios
        .post("https://main-api.apptobus.com/api/auth/update", this.user, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => router.push("/"))
        .catch((err) => console.error(err));
    },
  },
};
</script>
